import React from "react";
import { ISingleDataset } from "../../types/datasets";
import clsx from "clsx";
import {
  ChevronDownIcon,
  ChevronRightIcon,
  EnvelopeIcon,
} from "@heroicons/react/24/outline";
import { parseDatasetTag } from "../../utils";
import Tag from "../Tag";
import { SunshineSupport } from "../../constants";
import { Disclosure } from "@headlessui/react";
import ReactMarkdown from "react-markdown";
import { StarIcon } from "@heroicons/react/24/solid";

interface DatasetDetailsProps {
  dataset: ISingleDataset;
  translations: { [key: string]: string };
}

const DatasetDetails: React.FC<DatasetDetailsProps> = ({
  dataset,
  translations,
}) => {
  const tags = Array.from(
    new Set([
      parseDatasetTag(dataset?.serviceCategory || "", translations),
      parseDatasetTag(dataset?.serviceSubcategory || "", translations),
    ]),
  ).filter((tag) => tag != null);

  return (
    <div
      className="flex flex-col w-full mt-3 pt-2 pb-10 px-6 xs:px-8 sm:px-11 md:px-14 lg:px-18 xl:px-20 
border-t-4 bg-white border-white border-t-blue-900"
    >
      <div
        className={clsx(
          "my-3 pl-2 sm:pl-4 pt-3 pb-2",
          dataset?.isFeatured ? "pr-2 sm:pr-4" : "pr-8 sm:pr-12",
        )}
      >
        <div className="flex item-center">
          <h1 className="flex flex-1 text-2xl md:text-3xl lg:text-4xl font-semibold">
            {dataset?.name}
          </h1>
          {dataset?.isFeatured && (
            <div className="flex items-center ml-2 md:ml-3">
              <StarIcon title="Featured" className="w-6 h-6 text-blue-400" />
            </div>
          )}
        </div>

        <div className="mt-2 md:mt-3 xl:mt-4">
          <ul className="flex flex-wrap">
            {tags.map((tag, index) => (
              <li
                key={`${index}-${tag}`}
                className="mr-2 xs:mr-3 sm:mr-4 py-0.5"
              >
                <Tag id={`${index}`} name={tag} />
              </li>
            ))}
          </ul>
        </div>

        <div className="text-bam-blue mt-2 md:mt-3 xl:mt-4">
          <a
            href={`mailto:${SunshineSupport}`}
            className="flex gap-x-1 tracking-wide text-lg"
          >
            <div>
              <span className="sr-only">Email</span>
              <EnvelopeIcon className="w-6 h-6 mt-px" />
            </div>
            Contact
          </a>
        </div>
      </div>
      <div className="mt-1 xl:text-lg">
        <Disclosure defaultOpen as="div">
          {({ open = true }) => (
            <>
              <Disclosure.Button className="flex w-full items-start justify-between text-left py-4 sm:py-5 md:py-6 px-2 sm:px-4 border-t border-t-gray-300 hover:bg-gray-50">
                <h2 className="text-lg sm:text-xl font-semibold text-gray-800">
                  Summary
                </h2>
                <div className="flex">
                  <span className="mt-1 ml-6 flex h-6 items-center">
                    {open ? (
                      <ChevronDownIcon className="h-6 w-6" aria-hidden="true" />
                    ) : (
                      <ChevronRightIcon
                        className="h-6 w-6"
                        aria-hidden="true"
                      />
                    )}
                  </span>
                </div>
              </Disclosure.Button>
              <Disclosure.Panel
                as="div"
                className="pl-2 pr-8 sm:pl-4 sm:pr-12 pt-0.5 md:pt-1 pb-4 xs:pb-5 md:pb-6 lg:pb-7 xl:pb-8 leading-relaxed"
              >
                <h3 className="font-semibold text-600">
                  {dataset?.shortServiceDescription}
                </h3>
                <ReactMarkdown className="my-4 leading-relaxed">
                  {dataset?.detailedServiceDescription || ""}
                </ReactMarkdown>
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>

        <Disclosure defaultOpen as="div">
          {({ open = true }) => (
            <>
              <Disclosure.Button className="flex w-full items-start justify-between text-left py-4 sm:py-5 md:py-6 px-2 sm:px-4 border-t border-t-gray-300 hover:bg-gray-50">
                <h2 className="text-lg sm:text-xl font-semibold text-gray-800">
                  At-A-Glance
                </h2>
                <div className="flex">
                  <span className="mt-1 ml-6 flex h-6 items-center">
                    {open ? (
                      <ChevronDownIcon className="h-6 w-6" aria-hidden="true" />
                    ) : (
                      <ChevronRightIcon
                        className="h-6 w-6"
                        aria-hidden="true"
                      />
                    )}
                  </span>
                </div>
              </Disclosure.Button>
              <Disclosure.Panel
                as="div"
                className="pl-2 pr-8 sm:pl-4 sm:pr-12 pt-0.5 md:pt-1 pb-4 xs:pb-5 md:pb-6 lg:pb-7 xl:pb-8 leading-loose"
              >
                <div className="border-b border-b-gray-200 py-3 flex flex-wrap justify-between">
                  <div className="font-semibold text-600">History Start</div>
                  <div>
                    <div>{dataset?.historicalData}</div>
                  </div>
                </div>
                <div className="border-b border-b-gray-200 py-3 flex flex-wrap justify-between">
                  <div className="font-semibold text-600">Update Frequency</div>
                  <div>
                    {parseDatasetTag(dataset?.updateFrequency, translations)}
                  </div>
                </div>
                <div className="border-b border-b-gray-200 py-3 flex flex-wrap justify-between">
                  <div className="font-semibold text-600">Point in Time</div>
                  <div>
                    {dataset?.pointInTime ? <div>Yes</div> : <div>No</div>}
                  </div>
                </div>
                <div className="border-b border-b-gray-200 py-3 flex flex-wrap justify-between">
                  <div className="font-semibold text-600">Coverage</div>
                  <div>
                    <div>{dataset?.publicCompaniesCovered}</div>
                  </div>
                </div>
                <div className="pt-2 flex flex-wrap justify-between">
                  <div className="font-semibold text-600">Geography</div>
                  <div>
                    {parseDatasetTag(
                      dataset?.applicableGeography || [],
                      translations,
                    )}
                  </div>
                </div>
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
      </div>
    </div>
  );
};

export default DatasetDetails;
